const IMAGE_PATH = '/images/icons/links/';
const IMAGE_NAME_SUFFIX = '_75.png';

function LinkItem(props) {
  return (
    <div id="links_item" className="col-lg-2 col-sm-3 col-4">
      <a target="_blank" rel="noreferrer" href={props.link}>
        <img
          alt={props.name}
          title={props.name}
          src={IMAGE_PATH + props.name.replace(' ', '_').toLowerCase() + IMAGE_NAME_SUFFIX}
        />
        <br />
        {props.name}
      </a>
    </div>
  );
}

export default LinkItem;
