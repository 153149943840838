import { Helmet } from 'react-helmet';
import * as CONSTANTS from '../constants';
import CardItem from '../components/CardItem.js';

const PAGE_TITLE = 'Web Development';

function WebDevelopment() {
  return (
    <div>
      <Helmet>
        <title>
          {PAGE_TITLE} | {CONSTANTS.GLOBAL_NAME}
        </title>
      </Helmet>
      <h3>{PAGE_TITLE}</h3>
      While the majority of my current and recent work isn't publicly disclosable (or accessible), I have worked with
      many different organizations and companies over the years to design, build, and maintain their online presences.
      Some of those examples are shown below. For questions and inquiries, please contact me.
      <br />
      <br />
      <CardItem
        name="The Body/Mind/Soul Connection"
        image="bmsc.jpg"
        desc="Assist in management of WordPress site including design, functional improvements, and content updates."
      />
      <CardItem
        name="Menard, Inc."
        image="menards.jpg"
        desc="Direct employee that worked as a developer and development lead to maintain the Ecommerce presence for the
                            large home-improvement retailer, Menards. Applications were mainly built on full-stack Java using
                            open-source technologies such as Spring and Hibernate, and backed by an SQL-based relational database."
      />
      <CardItem
        name="Xbox 360 Digest"
        image="x3d.jpg"
        desc="Managing editor and founder. Responsible for all design and development, including heavy WordPress
                            template modification and deep integration with social media. Also responsible for hiring/managing staff
                            and daily content authorship."
      />
      <CardItem
        name="Country Club Supply, Inc."
        image="ccs.jpg"
        desc="Ecommerce website for a locker room supply wholesaler featuring several thousand products. Redesigned and
                            managed from 2006 until company sale in 2012, including design and implementation of new features such as
                            a new account system, improved checkout flow, search engine optimization, and online marketing."
      />
      <CardItem
        name="Mobility Digest"
        image="md.jpg"
        desc="Helped manage and maintain template designs, including theme modification and implementation using PHP,
                            HTML, and CSS. Assisted with work to combine network of sites into one entity with several sub-sites.
                            Served as contributing writer using WordPress platform. Former projects under Mobility Digest include Fuze
                            Mobility, Tilt Mobility, and Tilt Site."
      />
      <CardItem
        name="E. Dozier Consulting"
        image="edozier.jpg"
        desc="Informational site built from scratch using PHP, HTML, and CSS for a database consulting company to
                            advertise their services."
      />
      <CardItem
        name="Marquette University: Office of Student Development"
        image="osd.jpg"
        desc="Contributor responsible for content upkeep on the Office of Student Development website. Helped convert
                            site to new template design, as well as incorporation of new features using PHP, HTML, JavaScript and CSS.
                            Also contributed to iOS app design and development."
      />
      <CardItem
        name="The Locker Room Center of Excellence"
        image="lrcoe.jpg"
        desc="Informational site built from scratch using PHP, HTML, and CSS. Used in correlation with Country Club
                            Supply, Inc. to help provide professional locker room consulting services for country clubs."
      />
      <CardItem
        name="Marquette University: eLIMO project"
        image="muelimo.jpg"
        desc="Informational site for the Marquette University electric van conversion project. Took on as volunteer,
                            partially rebuilt and maintained. Also a contributing writer, using WordPress."
      />
      <CardItem
        name="Barrington High School Television"
        image="bhstv.jpg"
        desc="Introductory site for Barrington High School's (IL) startup television station, BHS-TV. Built site and
                            template from scratch as volunteer; maintained and passed on."
      />
      <CardItem
        name="Holy Family Parish Youth Ministries"
        image="hfym.jpg"
        desc="Website for Youth Ministries group of local church. Took on as volunteer, rebuilt from scratch,
                            maintained, and passed on."
      />
      <CardItem
        name="Pkort.net"
        image="pkort.jpg"
        desc="The precursor to pkort.com, pkort.net was built from scratch, featuring a variety of entertainment
                            including jokes, games, email service and more."
      />
    </div>
  );
}

export default WebDevelopment;
