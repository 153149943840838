import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as CONSTANTS from '../constants';

const PAGE_TITLE = 'Archive';

function Archive() {
  return (
    <div>
      <Helmet>
        <title>
          {PAGE_TITLE} | {CONSTANTS.GLOBAL_NAME}
        </title>
      </Helmet>
      <h3>{PAGE_TITLE}</h3>
      <p>Listed below are some older pages that are available on my site:</p>
      <ul>
        <li>
          <Link to="/app_development">App Development</Link> - A partial list of mobile applications that I have worked
          on.
        </li>
        <li>
          <Link to="/links">Links</Link> - Links to a variety of websites that I use and/or work with.
        </li>
        <li>
          <Link to="/web_development">Web Development</Link> - A partial list of websites that I have worked on.
        </li>
        <li>
          <Link to="/writing">Writing</Link> - Some writing samples from when I was an online media reporter.
        </li>
      </ul>
    </div>
  );
}

export default Archive;
