import { Link } from 'react-router-dom';
import SocialItem from '../components/SocialItem.js';

function Footer() {
  return (
    <footer>
      &copy;{new Date().getFullYear()} Patrick Kortendick
      <br />
      <SocialItem name="LinkedIn" link="https://linkedin.com/in/ptk8ofbh" />
      <SocialItem name="Twitter" link="https://twitter.com/pkort" />
      <SocialItem name="Facebook" link="https://facebook.com/pkort" />
      <SocialItem name="Foursquare" link="https://foursquare.com/pkort/" />
      <SocialItem name="Github" link="https://github.com/ptk8ofbh" />
      <br />
      <Link to="/archive" target="_blank">
        Archive
      </Link>
    </footer>
  );
}

export default Footer;
