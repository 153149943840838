import { Helmet } from 'react-helmet';
import * as CONSTANTS from '../constants';
import LinkItem from '../components/LinkItem.js';

const PAGE_TITLE = 'Links';

function Links() {
  return (
    <div>
      <Helmet>
        <title>
          {PAGE_TITLE} | {CONSTANTS.GLOBAL_NAME}
        </title>
      </Helmet>
      <h3>{PAGE_TITLE}</h3>
      Here are some links to various websites or services that I use and/or work with. Several of these include referral
      promotions for new users by using the below link. For any questions or more information, feel free to contact me.
      <br />
      <div id="grid_section" className="row">
        <LinkItem name="Amazon" link="https://amzn.to/2XORE01" />
        <LinkItem name="AwardWallet" link="https://awardwallet.com/?refCode=mp3jumfn5x" />
        <LinkItem name="DoorDash" link="https://drd.sh/aVeMpz/" />
        <LinkItem
          name="Dropbox"
          link="https://www.dropbox.com/referrals/AAARE1m_kV-mJubayAtQrGNRZfQPdjUvVlI?src=global9"
        />
        <LinkItem name="Google Fi" link="https://g.co/fi/r/U86EXM" />
        <LinkItem name="Lyft" link="https://www.lyft.com/i/PATRICK251767" />
        <LinkItem name="Rakuten" link="https://www.rakuten.com/r/PTK8OF?eeid=28187" />
        <LinkItem name="Robinhood" link="https://share.robinhood.com/patrick77" />
        <LinkItem
          name="SoFi"
          link="https://www.sofi.com/invite/money?gcp=b7ab4583-b019-4e06-af74-96498e3359a7&isAliasGcp=false"
        />
        <LinkItem name="Uber" link="https://www.uber.com/invite/uberpkort" />
        <LinkItem name="Wealthfront" link="https://wlth.fr/1MpKXGv" />
      </div>
    </div>
  );
}

export default Links;
