import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from '../pages/Home.js';
import AppDevelopment from '../pages/AppDevelopment.js';
import Archive from '../pages/Archive.js';
import Links from '../pages/Links.js';
import TravelMap from '../pages/TravelMap.js';
import WebDevelopment from '../pages/WebDevelopment.js';
import Writing from '../pages/Writing.js';
import Error from '../pages/Error.js';

const Main = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/app_development" element={<AppDevelopment />}></Route>
      <Route path="/archive" element={<Archive />}></Route>
      <Route path="/links" element={<Links />}></Route>
      <Route path="/travel_map" element={<TravelMap />}></Route>
      <Route path="/web_development" element={<WebDevelopment />}></Route>
      <Route path="/writing" element={<Writing />}></Route>
      <Route path="*" element={<Error />} /> {/* TODO set 404 status */}
    </Routes>
  );
};

export default Main;
