import Header from './Header.js';
import Footer from './Footer.js';
import Main from './Main.js';
import * as CONSTANTS from '../constants';

function App() {
  return (
    <div id="App">
      {CONSTANTS.IS_LOCAL && <div className="bg-info text-center">LOCAL</div>}
      <Header />
      <article>
        <Main />
      </article>
      <Footer />
    </div>
  );
}

export default App;
