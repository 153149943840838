import { Helmet } from 'react-helmet';
import * as CONSTANTS from '../constants';

const PAGE_TITLE = 'Error';

function Error() {
  return (
    <div>
      <Helmet>
        <title>
          {PAGE_TITLE} | {CONSTANTS.GLOBAL_NAME}
        </title>
      </Helmet>
      <h3>{PAGE_TITLE}</h3>
      There was an error rendering the requested URL.
    </div>
  );
}

export default Error;
