const IMAGE_PATH = '/images/thumb/web/';

function CardItem(props) {
  return (
    <div className="card">
      <div className="card-header">{props.name}</div>
      <div className="card-body">
        <div className="row">
          <div className="col-sm-3">
            <img src={IMAGE_PATH + props.image} alt={props.name} title={props.name} width="175" />
          </div>
          <div className="col-sm-9">
            {props.desc}
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardItem;
